/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const submitCustomWepEvent = /* GraphQL */ `
  mutation SubmitCustomWepEvent($rawEvent: String!) {
    submitCustomWepEvent(rawEvent: $rawEvent) {
      message
      success
    }
  }
`;
export const submitCustomNoCustomsTreatmentNeededEvent = /* GraphQL */ `
  mutation SubmitCustomNoCustomsTreatmentNeededEvent($rawEvent: String!) {
    submitCustomNoCustomsTreatmentNeededEvent(rawEvent: $rawEvent) {
      message
      success
    }
  }
`;
export const submitCustomCustomsReleasedEvent = /* GraphQL */ `
  mutation SubmitCustomCustomsReleasedEvent($rawEvent: String!) {
    submitCustomCustomsReleasedEvent(rawEvent: $rawEvent) {
      message
      success
    }
  }
`;
export const submitNodeInventoryEvent = /* GraphQL */ `
  mutation SubmitNodeInventoryEvent($rawEvent: String!) {
    submitNodeInventoryEvent(rawEvent: $rawEvent) {
      message
      success
    }
  }
`;
export const submitNodeInventoryLockEvent = /* GraphQL */ `
  mutation SubmitNodeInventoryLockEvent($rawEvent: String!) {
    submitNodeInventoryLockEvent(rawEvent: $rawEvent) {
      message
      success
    }
  }
`;
export const submitNodeGoodsHolderItemReceivedEvent = /* GraphQL */ `
  mutation SubmitNodeGoodsHolderItemReceivedEvent($rawEvent: String!) {
    submitNodeGoodsHolderItemReceivedEvent(rawEvent: $rawEvent) {
      message
      success
    }
  }
`;
export const submitNodeGoodsReceivedEvent = /* GraphQL */ `
  mutation SubmitNodeGoodsReceivedEvent($rawEvent: String!) {
    submitNodeGoodsReceivedEvent(rawEvent: $rawEvent) {
      message
      success
    }
  }
`;
export const submitShipmentEvent = /* GraphQL */ `
  mutation SubmitShipmentEvent($rawEvent: String!) {
    submitShipmentEvent(rawEvent: $rawEvent) {
      message
      success
    }
  }
`;
export const submitDeductionEvent = /* GraphQL */ `
  mutation SubmitDeductionEvent($rawEvent: String!) {
    submitDeductionEvent(rawEvent: $rawEvent) {
      message
      success
    }
  }
`;
export const submitDummyDeductionEvent = /* GraphQL */ `
  mutation SubmitDummyDeductionEvent($rawEvent: String!) {
    submitDummyDeductionEvent(rawEvent: $rawEvent) {
      message
      success
    }
  }
`;
