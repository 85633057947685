import "@nike/eds/dist/index.css";
import {RawEvent} from "./components/RawEvent";
import {Route, Routes} from 'react-router-dom'
import React from "react";
import {SimulationService} from "./service/SimulationService";

const simulationService: SimulationService = new SimulationService()

function App() {

    return (
        <div style={{backgroundColor: "white"}}>
            <Routes>
                <Route path="/simulation/raw-event" element={<RawEvent simulationService={simulationService}/>}/>
                <Route path="*" element={<span>Invalid route for simulation.</span>}/>
            </Routes>
        </div>
    );
}

export default App;
