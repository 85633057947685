import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as ReactDOMClient from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {SnackBarContextProvider} from "node.glds-react-component-library";

let simulationContainer = null;
let root = null;

///for testing purposes
// let containerId = 'root'
// simulationContainer = document.getElementById(containerId);
// root = ReactDOMClient.createRoot(simulationContainer);
// root.render(
//     <React.StrictMode>
//         <BrowserRouter>
//             <App />
//         </BrowserRouter>
//     </React.StrictMode>
// );
///

window.renderSimulation = (containerId, history) => {
    if (!simulationContainer) {
        simulationContainer = document.getElementById(containerId);
        root = ReactDOMClient.createRoot(simulationContainer);
        root.render(
            <React.StrictMode>
                <BrowserRouter>
                    <SnackBarContextProvider>
                        <App history={history}/>
                    </SnackBarContextProvider>
                </BrowserRouter>
            </React.StrictMode>
        );
        serviceWorker.unregister();
    }

};

window.unmountSimulation = containerId => {
    root.unmount()
    simulationContainer = null;
    root = null;
};

if (!document.getElementById('Simulation-container')) {
    //root.render(<App />);
    serviceWorker.unregister();
}

